html,
body {
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  html,
  body {
    font-size: 12px;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

h1 {
  color: #fff;
}

h4 {
  margin: 0.5rem 0 1rem;
}

a {
  cursor: pointer;
  color: #1e88e5;
}

a.link-info {
  color: #1e88e5;
}

.avatar {
  flex: 0 0 auto;
}

/* header {
  align-items: flex-start;
  display: flex;
  height: 80px;
  padding: 1rem 0 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
} */

main {
  margin-top: 97px;
}

main.full-screen {
  position: absolute;
  width: 100%;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.grey {
  background: #f5f5f5;
}

.blue {
  background: #3679b3;
}

.error {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
}

.error-radio label:before {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
}

.ui.button,
.ui.button.loading,
.ui.button.disabled {
  transition: all 0.2s !important;
}

.message.small-icon .icon {
  font-size: 1.5rem !important;
}

header .inner {
  align-items: center;
  display: flex;
  flex: 0 1 1200px;
  padding: 0 2rem;
  position: relative;
}

@media screen and (max-width: 800px) {
  header .inner {
    padding: 0 1rem;
  }
}

header .inner .menu {
  margin-left: auto !important;
}

header .inner .menu .item a {
  cursor: pointer;
  font-weight: 700 !important;
}

header .inner .menu .item a.becomeatester {
  background: #83b639;
  border-radius: 3px;
  padding: 0.625rem;
}

.page {
  display: flex;
  flex-direction: column;
}

.page .heading {
  align-items: center;
  display: flex;
  height: 132px;
  margin-bottom: 2rem;
  position: relative;
}

.page.cycles .heading {
  height: 100px;
}

.page.cycles .heading h1 {
  margin-top: -1.5rem;
}

@media screen and (max-width: 800px) {
  .page .heading {
    height: 180px;
  }
}

.page .heading:before {
  background: rgba(48, 111, 165, 0.5);
  border-bottom: 10px solid rgba(48, 111, 165, 0.7);
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.page .heading .inner {
  color: #fff;
  position: relative;
  z-index: 2;
}

.page h1 {
  font-size: 2rem;
  text-align: left;
  margin: 4.5rem auto 0;
  max-width: 1200px;
  padding: 0 2rem;
  width: 100%;
  z-index: 1;
}

.home.page {
  margin-bottom: 0;
}

.home .heading {
  display: flex;
  flex-direction: column;
  background: url('public/images/homeHeader.png') no-repeat 0 0 / cover;
  height: 700px;
  justify-content: center;
  padding: 80px 2rem;
}

.home .heading:before {
  display: none;
}

.home .heading h1 {
  font-size: 4rem;
  letter-spacing: -1px;
  margin-bottom: 4rem;
  text-align: center;
  text-shadow: 4px 4px 4px #000;
}

@media screen and (max-width: 800px) {
  .home .heading {
    padding: 80px 1rem;
  }

  .home .heading h1 {
    font-size: 3rem;
    margin-top: 0;
  }

  header .logo {
    margin-right: 0.5rem;
    max-width: 30%;
  }

  header .logo img {
    max-width: 100%;
  }
}

.home .heading .introtext {
  color: #fff;
  font-size: 1.75rem;
  line-height: 1.75rem;
  font-weight: 400;
  text-align: center;
  text-shadow: 4px 4px 4px #000;
}

.home .heading .intro-buttons {
  display: flex;
  justify-content: center;
}

.home .heading .intro-buttons a {
  color: #fff;
  font-size: 1.5rem;
  margin: 3rem 1rem;
}

.home .heading .intro-buttons i {
  color: #ffad33;
}

.home .heading .start-here {
  background: #ffad33;
  border-radius: 3px;
  color: #fff;
  font-size: 2rem;
  margin: 1rem auto;
  padding: 1.25rem;
}

.home .partners {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.home h2 {
  color: #3679b3;
  font-size: 2.5rem;
  text-align: center;
}

.login-modal .button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.register-modal-header {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.register-modal-header img {
  margin-left: 1rem;
}

.register-modal .content {
  align-items: stretch;
  display: flex !important;
  flex-direction: column;
}

.register-modal .account-type {
  align-items: center;
  color: #3679b3;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  margin: 0 0 1rem;
}

.register-modal .account-type label {
  margin-left: 1rem;
}

.register-modal .facebook-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.register-modal .modal-link {
  display: flex;
}

.register-modal .modal-link a {
  margin-left: 0.5rem;
}

.register-modal .submit-button {
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  text-align: center;
}

.register-modal .modal-link {
  justify-content: center;
}

.partners .logos {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}

.partners .logos img {
  height: auto;
  margin: 1rem;
  flex: 1 1 160px;
  max-width: 200px;
}

@media screen and (max-width: 800px) {
  .partners .logos {
    flex-direction: column;
  }

  .partners .logos img {
    flex-basis: auto;
  }
}

.howitworks,
.home .description {
  padding: 4rem;
}

.howitworks {
  display: flex;
  flex-direction: column;
}

.steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 4rem;
}

.steps .step {
  display: flex;
  flex: 0 1 340px;
  margin: 3rem 1rem 1rem;
  min-height: 340px;
  position: relative;
}

.steps .step:before {
  align-items: center;
  background: #ffad33;
  border: 14px solid #f5f5f5;
  border-radius: 50%;
  content: attr(data-num);
  display: flex;
  font-size: 2rem;
  justify-content: center;
  position: absolute;
  top: -68px;
  left: calc(50% - 48px);
  height: 96px;
  width: 96px;
  z-index: 2;
}

.steps .step:after {
  border: 6px solid #ff8419;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: -54px;
  left: calc(50% - 34px);
  height: 68px;
  width: 68px;
  z-index: 3;
}

.steps .step1 {
  background: url(public/images/step1.jpg) no-repeat center center / cover;
}

.steps .step2 {
  background: url(public/images/step2.jpg) no-repeat center center / cover;
}

.steps .step3 {
  background: url(public/images/step3.jpg) no-repeat center center / cover;
}

.steps .step-text {
  background: rgba(30, 75, 113, 0.9);
  bottom: 0;
  color: #fff;
  height: 86px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  transition: height 0.5s;
  width: 100%;
}

.steps .step-text:hover {
  height: 100%;
}

.steps .step-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0 1rem 1rem;
  padding: 2rem 0;
}

.steps .step-description {
  display: flex;
  font-style: italic;
  padding: 2rem;
}

.page h2,
h3 {
  color: #1d4b71;
  font-size: 2rem;
}

.complete-profile-tester .heading,
.becomeatester .heading,
.forgotpassword .heading,
.samplereports .heading,
.password-reset .heading {
  background: url('public/images/becomeatesterHeader.jpg') no-repeat 0 -120px / 100% 160%;
}

.samplereports .sample-report-tabs {
  margin: 0 auto 2rem;
  max-width: 1200px;
}

.samplereports .sample-report-tabs .menu {
  display: flex;
  flex-wrap: wrap;
}

.samplereports .sample-report-tabs p {
  color: #1d4b71;
  font-size: 1.5rem;
  text-align: center;
}

.samplereports .sample-report-tabs .tab {
  border: 0 none;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
}

.samplereports .sample-report-tabs img {
  max-width: 100%;
}

.becomeatester .makepay {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
}

@media screen and (max-width: 800px) {
  .becomeatester .makepay {
    justify-content: center;
    padding: 0;
  }

  .becomeatester .makepay h2 {
    text-align: center;
  }
}

.becomeatester .makepay > div {
  max-width: 75%;
}

.becomeatester .becomeatester-button {
  background: #ffad33;
  font-size: 1.25rem;
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
  margin: 1rem;
  min-width: 250px;
}

.becomeatester .becomeatester-button.large {
  display: block;
  padding: 1.5rem 2rem;
  font-size: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.becomeatester .howitworks h3 {
  text-align: center;
}

.becomeatester .steps .step {
  flex: 0 1 280px;
}

.becomeatester .steps .step:before {
  border-color: #fff;
}

.becomeatester .steps .step1 {
  background: url(public/images/testerStep1.jpg) no-repeat center center / cover;
}

.becomeatester .steps .step2 {
  background: url(public/images/testerStep2.jpg) no-repeat center center / cover;
}

.becomeatester .steps .step3 {
  background: url(public/images/testerStep3.jpg) no-repeat center center / cover;
}

.becomeatester .steps .step4 {
  background: url(public/images/testerStep4.jpg) no-repeat center center / cover;
}

.becomeatester .getpaid {
  padding: 2rem;
}

.becomeatester .privacy {
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 500px;
}

.becomeatester .privacy img {
  display: flex;
  flex: 1 0 auto;
  margin: 1rem;
}

.becomeatester .bottom {
  padding: 4rem;
}

.becomeatester .privacy p {
  margin: 1rem;
}

.forgotpassword .inner {
  max-width: 400px;
  margin: 2rem auto 8rem;
}

.home .carousel {
  padding: 4rem 0;
}

.home .slick-slide {
  color: #fff;
  text-align: center;
  padding: 148px 0 2rem;
}

.home .slick-slide h3 {
  color: #fff;
  font-size: 2.5rem;
}

.home .slick-slide p {
  font-size: 1.5rem;
  margin: 0 0.5rem;
}

.home .slick-slide:nth-child(2) {
  background: url(public/images/slide1.png) no-repeat center 0;
}

.home .slick-slide:nth-child(3) {
  background: url(public/images/slide2.png) no-repeat center 0;
}

.home .slick-slide:nth-child(4) {
  background: url(public/images/slide3.png) no-repeat center 0;
}

.home .slick-slide:nth-child(5) {
  background: url(public/images/slide4.png) no-repeat center 0;
}

.home .testimonials {
  background: #fff;
  padding: 4rem;
}

.home .testimonials h2 {
  text-align: left;
  margin-bottom: 2rem;
}

.home .testimonials-item {
  align-items: flex-start;
  display: flex;
  margin-bottom: 4rem;
}

.home .testimonials-item img {
  display: flex;
  flex: 1 0 160px;
  margin-right: 3rem;
}

@media screen and (max-width: 800px) {
  .home .testimonials {
    padding: 1rem;
  }

  .home .testimonials h2 {
    text-align: center;
  }

  .home .testimonials-item img {
    margin-right: 1rem;
    flex-shrink: 0.5;
  }
}

.home .testimonials h3 {
  color: #3679b3;
}

.home .testimonials .position {
  font-weight: 700;
  margin-bottom: 1rem;
}

.cycles .heading {
  background: url('public/images/cycleHeader.jpg') no-repeat 0 0 / cover;
  margin-bottom: 0;
}

.cycles .cycles-dimmer p {
  color: #000 !important;
  font-size: 1.5rem;
}

.cycles .heading:before {
  border: 0 none;
}

.cycles .cycle-steps-nav {
  background: rgba(48, 111, 165, 0.9);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  height: 5rem;
  margin-top: -5rem;
  position: relative;
  z-index: 2;
}

.cycles .cycle-steps-nav .step {
  color: #94c5f0;
  display: flex;
  flex: 0 1 400px;
  font-size: 1.25rem;
  justify-content: center;
  padding: 3rem 0 0.5rem;
  position: relative;
  z-index: 1;
}

.cycles .cycle-steps-nav .step:before {
  align-items: center;
  background: #4795d9;
  border-radius: 50%;
  color: #356c9b;
  content: attr(data-num);
  display: flex;
  justify-content: center;
  position: absolute;
  top: 1rem;
  left: calc(50% - 16px);
  height: 32px;
  width: 32px;
  z-index: 2;
}

.cycles .cycle-steps-nav .step.active {
  color: #fff;
}

.cycles .cycle-steps-nav .step.active:before {
  background: #ffad33;
  color: #000;
}

.cycles .cycle-steps-nav .step.active:after {
  background: rgba(48, 111, 165, 1);
  content: '';
  position: absolute;
  bottom: -8px;
  transform: rotate(45deg);
  height: 16px;
  width: 16px;
  z-index: -1;
}

.cycles .cycle-steps-nav .step:hover {
  color: #fff;
  cursor: pointer;
}

.cycles .cycle-steps-nav .step:hover:before {
  background: #fff;
  color: #000;
}

.cycles .cycle-step {
  display: flex;
  margin: 2rem 0;
  flex: 1 0 100%;
  margin: 2rem auto;
  max-width: 1200px;
  padding: 0 1rem;
  width: 100%;
}

.cycles .cycle-step aside {
  border-right: 1px solid #4795d9;
  display: flex;
  flex-direction: column;
  flex: 0 0 300px;
  font-size: 0.875rem;
  padding-right: 1rem;
  margin-right: 2rem;
}

.cycles .cycle-step aside h3 {
  color: #4795d9;
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}

.cycles .cycle-step label {
  display: flex;
  margin-bottom: 1rem;
}

.cycles .cycle-step aside .dropdown {
  width: 100%;
}

.cycles .cycle-step aside .toggle {
  margin: 0.5rem 0 -0.5rem;
}

.cycles .nav-button {
  margin: 0.5rem 0;
  width: 100%;
}

.cycles .next-step-button {
  background: #ffad33;
}

.tester-list {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  width: 100%;
}

.tester-preview {
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  padding: 1rem 0 0;
}

.tester-preview div {
  display: flex;
}

.tester-preview .avatar {
  border-radius: 50%;
  margin: 0 1rem 1rem 1rem;
}

.tester-preview .tester-summary {
  align-items: flex-start;
  display: flex;
}

.tester-preview .tester-summary-heading {
  align-items: center;
  display: flex;
  margin: 0 0 0.25rem;
}

.tester-preview .tester-name {
  font-size: 1.5rem;
  margin: 0 0.5rem;
}

.tester-preview .dv-star-rating {
  font-size: 1.5rem;
  padding: 0 0.5rem;
}

.tester-preview.favorite-tester .dv-star-rating {
  padding: 0 0.5rem 0 0;
}

.tester-preview.favorite-tester {
  flex-direction: row;
}

.tester-preview .dv-star-rating label {
  display: flex;
  margin-bottom: 0;
}

.tester-preview .about-tester {
  white-space: pre-wrap;
  word-wrap: anywhere;
}

.tester-preview .tester-stats {
  background: #f5f5f5;
  padding: 0.5rem;
}

.cycles .cycle-step1 .tester-list .tester-preview .about-tester {
  padding: 0 1rem 1rem;
}

.cycles .cycle-step1 .optional-block {
  max-height: 0;
  overflow: hidden;
  transition: all 1s;
}

.cycles .cycle-step1 .optional-block.visible {
  max-height: 300px;
}

.cycles .cycle-step1 .browser-details {
  margin-bottom: 1rem;
}

.cycles .cycle-step2 .num-testers-table {
  background: #4795d9;
  color: #fff;
  margin-bottom: 1rem;
  padding: 0.25rem 1rem;
  text-align: left;
  width: 100%;
}

.cycles .cycle-step2 .cycle-details {
  display: flex;
  flex-direction: column;
  flex: 1 0 calc(100% - 300px);
}

.cycles .cycle-step2 .sample-guideline {
  align-self: end;
  display: flex;
  margin-top: 0.25rem;
}

.cycles .cycle-step2 .platform-url {
  display: flex;
  margin: 0.5rem 0 0;
}

.cycles .cycle-step2 .platform-url input {
  border-radius: 4px 0 0 4px;
}

.cycles .cycle-step2 .platform-url a {
  align-items: center;
  display: flex !important;
  flex: 1 0 auto;
  justify-content: center;
}

.cycles .cycle-step2 .cycle-questions .upload-testing-plan {
  float: right;
}

.cycles .cycle-step2 .testing-plan-table .input {
  width: 100%;
}

.cycles .cycle-step .total-summary table td {
  padding: 0.25rem;
}

.cycles .cycle-step2 .how-many-testers label {
  display: flex;
}

.cycles .cycle-step2 .how-many-testers label {
  align-items: center;
  background: #f5f5f5;
  border-radius: 2px;
  display: flex;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
}

.cycles .cycle-step2 .how-many-testers .input {
  display: flex;
  margin-left: auto;
  max-width: 64px;
}

.cycles .cycle-step2 .how-many-hours {
  margin-top: 2rem;
  position: relative;
}

.cycles .cycle-step2 .how-many-hours:before {
  border-top: 3px solid #ccc;
  content: '';
  position: absolute;
  top: -1rem;
  left: 0;
  width: 100%;
}

.cycles .cycle-step2 .cycle-details-form {
  display: flex;
  flex-direction: column;
}

.cycles .cycle-step2 .cycle-details-form label {
  margin: 1rem 0 0.5rem;
}

.cycles .cycle-step2 .cycle-details-form .project-title,
.cycles .cycle-step2 .cycle-details-form .version,
.cycles .cycle-step2 .cycle-details-form .project-description {
  display: flex;
  flex-direction: column;
}

.cycles .cycle-step2 .cycle-details-form .project-title {
  flex: 1 1 75%;
}

.cycles .cycle-step2 .cycle-details-form .version {
  flex: 1 0 25%;
  margin-left: 1rem;
  max-width: 128px;
}

.cycles .cycle-step2 .cycle-details-form .project-description textarea {
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 6px;
  min-height: 168px;
  padding: 1rem;
  width: 100%;
}

.cycles .cycle-step2 .cycle-details-form .project-description .sample-guide {
  font-weight: 700;
  margin: 0.5rem 0 0.5rem auto;
}

.cycles .cycle-step2 .cycle-details-form .download-url .input {
  width: 100%;
}

.cycles .cycle-step2 .cycle-details-form .testing-approach .radio label {
  margin: 0 1rem 0 0;
}

.cycles .cycle-step2 .questions-block {
  margin: 1rem 0;
  max-height: 800px;
}

.cycles .cycle-step2 .questions-block .toggle-btn {
  margin: 1rem 0;
}

.cycles .cycle-step2 .questions-block .question button {
  margin-bottom: 0.25rem !important;
}

.cycles .cycle-step2 .questions-block .question > div {
  display: flex;
  flex-direction: row;
  flex: 1 0 800px;
  max-width: 800px;
}

.cycles .cycle-step2 .questions-block .question > div .edit-btn,
.cycles .cycle-step2 .questions-block .question > div .save-btn {
  margin-left: auto !important;
}

.cycles .cycle-step2 .questions-block .question span {
  display: flex;
}

.cycles .cycle-step2 .questions-block .question button {
  margin-left: 0.5rem !important;
}

.cycles .cycle-step2 .our-questions .questions-list {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  max-height: 0;
  overflow: hidden;
  transition: all 1s;
}

.cycles .cycle-step2 .our-questions .questions-list.visible {
  max-height: 800px;
}

.cycles .cycle-step2 .our-questions .questions-list label {
  margin: 0.5rem 0 !important;
}

.cycles .cycle-step2 .our-questions .add-selected-btn {
  width: 240px;
}

.cycles .cycle-step2 .testing-plan-table label {
  margin: 0;
}

.cycles .cycle-step2 .testing-plan-table .edit-buttons {
  width: 90px;
}

.cycles .cycle-step3 {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.cycles .cycle-step3 h2 {
  text-align: center;
}

.cycles .cycle-summary {
  flex-direction: column;
  max-width: 800px;
  white-space: pre-wrap;
}

.cycles .cycle-step3 {
  min-width: 640px;
  max-width: 800px;
}

.cycles .cycle-step3 .nav-button {
  margin: 0.5rem auto;
  max-width: 360px;
}

.cycles .cycle-step3 .cycle-total p {
  font-size: 1.25rem !important;
}

.cycles .cycle-step3 .price p {
  display: flex;
  justify-content: space-between;
}

.cycles .cycle-step3 .price .amount-to-pay {
  padding: 1rem 0 0;
}

.cycles .cycle-step3 .price .amount-to-pay span {
  font-size: 1.25rem;
  border-bottom: 1px solid;
}

.cycle-created .heading {
  background: url('public/images/cycleHeader.jpg') no-repeat 0 0 / cover;
  margin-bottom: 0;
}

.cycle-created .inner {
  font-size: 2rem;
  min-height: 600px;
  padding: 2rem;
  text-align: center;
}

.terms {
  padding-bottom: 2rem;
}

.terms .heading {
  background: url('public/images/termsHeader.jpg') no-repeat 0 -270px / 100% 330%;
}

.privacy .heading,
.aboutus .heading {
  background: url('public/images/privacyHeader.jpg') no-repeat 0 -250px / 100% 330%;
}

.contactus {
  margin-top: 2rem;
  padding: 6rem;
}

.aboutus .info {
  display: flex;
  margin-bottom: 2rem;
}

.aboutus .info img {
  margin-right: 1rem;
}

.aboutus .partners {
  background: #f5f5f5;
  padding: 2rem;
}

.aboutus .leadership {
  padding: 2rem;
}

.aboutus .leadership h3 {
  width: 100%;
}

.aboutus .leadership .photos {
  display: flex;
}

.aboutus .leadership .photos > div {
  align-items: flex-start;
  display: flex;
}

.aboutus .leadership .photos img {
  display: flex;
  flex: 1 0 200px;
  margin-right: 2rem;
}

.contact .heading,
.articles .heading,
.article .heading {
  background: url('public/images/contactHeader.jpg') no-repeat 0 -520px / 100% 330%;
}

.article {
  margin-bottom: 2rem;
}

.article .article-image {
  box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.2);
  display: block;
  margin: 1rem auto;
  width: 600px;
  max-width: 100%;
}

.contact h2 {
  text-align: center;
}

.contact-form {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  margin: 2rem auto;
  padding: 2rem;
  max-width: 600px;
}

.contact-form .send-button {
  margin: 1rem auto;
  max-width: 250px;
}

.contact.page .contact-form .input.error input,
.contact.page .contact-form textarea.error {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
  box-shadow: none;
}

.article-preview {
  align-items: flex-start;
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  margin: 0 0 1rem;
  padding: 1rem;
}

.article-preview img {
  margin-right: 1rem;
  max-width: 240px;
}

@media screen and (max-width: 800px) {
  .article-preview {
    flex-direction: column;
  }

  .article-preview img {
    margin: 0 0 1rem;
    width: 100%;
  }
}

.account-tester,
.account-customer {
  display: flex;
}

.account-tester .heading,
.account-customer .heading {
  background: url('public/images/cycleHeader.jpg') no-repeat 0 0 / cover;
}

.account-tester .inner,
.account-customer .inner {
  display: flex;
  margin-bottom: 2rem;
  max-width: 1200px;
  width: 100%;
}

.account-tester aside,
.account-customer aside {
  border: 1px solid #ccc;
  border-radius: 2px;
  display: flex;
  flex: 0 0 250px;
  flex-direction: column;
  margin-right: 1rem;
  max-width: 250px;
}

.account-tester aside .summary {
  display: flex;
  padding: 1rem;
}

.account-tester aside .summary .avatar {
  border-radius: 50%;
  margin-right: 1rem;
}

.account-tester aside .summary .name {
  font-size: 1.25rem;
}

.account-tester aside .summary .details .rating {
  display: flex;
}

.account-tester aside .summary .rating .dv-star-rating {
  font-size: 1.25rem;
}

.account-tester aside .summary .details {
  flex-direction: column;
}

.account-tester aside .summary .details .rating span {
  margin: 2px 0 0 2px;
}

.account-tester aside .summary .added-to-favorites {
  font-size: 0.75rem;
}

.account-tester aside .menu {
  border: 0 none !important;
  width: 100% !important;
}

.account-tester aside .i-have-finished {
  margin: 1rem;
}

.sidemenu-countdown {
  padding: 1rem;
}

.account-tester main {
  width: 100%;
}

.complete-profile-tester {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.complete-profile-tester .step-personal {
  display: flex;
  flex-direction: column;
}

.account-tester .personal-information,
.account-customer .personal-information {
  margin-bottom: 1rem;
  width: 100%;
}

.complete-profile-tester .personal-information td,
.account-tester .personal-information td,
.account-customer .personal-information td {
  padding: 0.25rem 0;
}

.complete-profile-tester .personal-information td:nth-child(odd),
.account-tester .personal-information td:nth-child(odd),
.account-customer .personal-information td:nth-child(odd) {
  padding-right: 0.5rem;
}

.complete-profile-tester .step-personal .input,
.complete-profile-tester .step-personal .dropdown,
.complete-profile-tester .step-personal textarea,
.account-tester .step-personal .input,
.account-tester .step-personal .dropdown,
.account-tester .step-personal textarea {
  display: flex;
  flex: 1 0 400px;
  width: 420px;
}

.complete-profile-tester .personal-information textarea,
.account-tester .personal-information textarea,
.account-customer .personal-information textarea {
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  min-height: 200px;
  padding: 1rem;
}

.complete-profile-tester .personal-information textarea,
.complete-profile-tester .personal-information input,
.account-tester .personal-information textarea,
.account-tester .personal-information input,
.account-customer .personal-information textarea,
.account-customer .personal-information input,
.account-customer .personal-information .ui.selection.dropdown,
.account-tester .personal-information .ui.selection.dropdown,
.complete-profile-tester .personal-information .ui.selection.dropdown {
  width: 420px;
}

.complete-profile-tester .save-and-next {
  display: flex;
  align-self: center;
  margin: 1rem 0;
}

.personal-information .avatar-cell {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.personal-information .avatar-cell .avatar {
  border-radius: 50%;
  margin-bottom: 0.5rem;
}

.complete-profile-tester .personal-information .gender-cell,
.account-tester .personal-information .gender-cell {
  display: flex;
  flex-direction: column;
}

.complete-profile-tester .personal-information .gender-cell label,
.account-tester .personal-information .gender-cell label {
  margin-bottom: 0.5rem;
}

.complete-profile-tester .my-devices {
  display: flex;
  flex-direction: column;
}

.complete-profile-tester .devices {
  display: flex;
  align-self: center;
  flex-direction: column;
  margin-bottom: 2rem;
  max-width: 680px;
}

.complete-profile-tester .devices .add {
  align-self: center;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin: 2rem 0;
  min-width: 400px;
  max-width: 600px;
}

.account-tester .devices .add .add-device-btn {
  align-self: flex-start;
}

.account-tester .devices .add,
.account-tester .browsers .add,
.complete-profile-tester .browsers .add {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  max-width: 500px;
}

.complete-profile-tester .devices .add label,
.complete-profile-tester .browsers .add label,
.account-tester .devices .add label,
.account-tester .browsers .add label {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.complete-profile-tester .devices .add label .dropdown,
.complete-profile-tester .browsers .add label .dropdown,
.account-tester .devices .add label .dropdown,
.account-tester .browsers .add label .dropdown {
  margin-left: auto;
}

.complete-profile-tester .browsers {
  display: flex;
  flex-direction: column;
}

.complete-profile-tester .desktop-types,
.account-tester .desktop-types {
  display: flex;
  flex-direction: column;
}

.account-tester .browsers .browsers-table {
  margin-bottom: 1rem;
}

.tester-profile-settings {
  display: flex;
  flex-direction: column;
}

.tester-profile-settings label {
  margin-bottom: 0.5rem;
}

.tester-profile-settings input {
  margin-bottom: 0.5rem;
  max-width: 80px;
}

.tester-profile-settings .save-btn {
  max-width: 200px;
}

.tester-profile-settings .min-hourly-rate {
  max-width: 200px;
}

.hourly-rate-notice {
  font-size: 0.875rem;
  margin: 0.25rem 0 0.75rem !important;
}

.hourly-rate-notice span {
  color: #c00;
  font-weight: 700;
}

/* .account-tester .tester-test-plan table .label { */
/*     margin: 0 0 1rem; */
/* } */

.account-tester .tester-test-plan .step-actions {
  width: 160px;
}

.account-tester .tester-test-plan .step-status {
  margin: 0 auto 1rem;
  text-align: center;
  width: 100%;
}

.account-tester .tester-test-plan .step-action-buttons {
  display: flex;
  justify-content: center;
}

.account-tester .customer-reviews {
  display: flex;
  flex-direction: column;
}

.account-tester .customer-reviews .customer-review {
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  margin: 0 0 1rem;
  padding: 1rem;
}

.account-tester .customer-reviews .customer-review .avatar {
  border-radius: 50%;
  margin-right: 1rem;
}

.account-tester .customer-reviews .customer-review .content {
  white-space: pre-wrap;
  width: 100%;
}

.account-tester .customer-reviews .customer-review .customer-name {
  color: #1d4b71;
  font-size: 1.25rem;
}

.account-tester .customer-reviews .customer-review .dv-star-rating {
  font-size: 1.5rem;
  margin: 0 0.25rem;
}

.account-tester .customer-reviews .customer-review .project-name {
  color: #333;
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;
}

.account-tester .customer-reviews .customer-review .review {
  overflow-wrap: anywhere;
}

.account-tester .cycle-newbug label {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  width: 100%;
}

.account-tester .cycle-newbug textarea {
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  padding: 1rem;
}

.account-tester .cycle-newbug .severity {
  align-self: baseline;
}

.bug-uploads {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
  padding: 1rem 0 0;
}

.bug-uploadsSlim {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  margin-bottom: 2rem;
  padding: 1rem 0 0;
}

.bug-uploadsSlim .upload {
  align-self: flex-start;
  display: flex;
  justify-content: center;
  margin: 0 0 1rem;
  min-width: 240px;
}

.bug-uploads > div {
  display: flex;
  flex: 1 0 40%;
  flex-direction: column;
  margin: 0 1rem 0 0;
}

.bug-uploads .upload {
  align-self: flex-start;
  display: flex;
  justify-content: center;
  margin: 0 0 1rem;
  min-width: 240px;
}

.bug-uploads .bug-image-thumb {
  height: auto;
  max-width: 200px;
}

.cycle-bugs .cycle-export .item {
  display: flex !important;
  flex-direction: column !important;
}

.cycle-bugs .cycle-export .item span {
  color: #888;
  font-size: 0.75rem;
}

.cycle-bugs .cycle-bugs-header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.cycle-bugs .active.title {
  background: #eee !important;
}

.cycle-bugs .filters {
  align-items: flex-start;
  display: flex;
  margin: 0 0 1rem;
}

.cycle-bugs .filters ui.dropdown {
  flex: 1 0 auto;
  margin: 0 0.5rem 0 0;
}

.cycle-bugs .filters .testers-filter {
  margin: 0 0.5rem;
}

.cycle-bugs .filters .testers-filter .item {
  align-items: center;
  display: flex !important;
  justify-content: space-between;
}

.cycle-bugs .review-controls {
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  margin: 0.5rem 0 1rem;
  padding: 0.75rem;
  position: relative;
  max-width: 360px;
}

.cycle-bugs .review-controls:after {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  background: #fff;
  content: '';
  height: 12px;
  position: absolute;
  top: -7px;
  transform: rotate(45deg);
  left: 90%;
  width: 12px;
}

.cycle-bugs .review-controls .button {
  flex: 1 0 auto;
}

.bug-list .bug-public-id {
  color: #000;
  font-weight: 700;
  flex-shrink: 0;
  margin: 0 1rem 0 0;
}

.bug-list .bug-step-labels {
  color: #999;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  font-size: 0.875rem;
  margin: 0 0 0 auto;
  padding: 0 0 0 1rem;
  text-align: right;
}

.cycle-bugs .bug-list .title,
.cycle-bugs .bug-list active.title {
  align-items: center;
  color: rgb(29, 75, 113) !important;
  display: flex;
  font-size: 1rem !important;
  overflow-wrap: anywhere;
  padding: 0.25rem 1rem !important;
}

.cycle-bugs .bug-list .title dropdown.icon,
.cycle-bugs .bug-list active.title dropdown.icon {
  color: #999;
  display: flex;
  flex: 0 0 20px;
}

.cycle-bugs .bug-list .title .dropdown.icon {
  flex: 0 0 20px;
}

.cycle-bugs .bug-list .title .label {
  display: flex;
  flex: 0 0 auto;
}

.cycle-bugs .bug-list .title .bug-list-title {
  display: flex;
  margin: 0;
  padding: 0;
}

.cycle-bugs .bug-list .title .bug-actions {
  flex-shrink: 0;
  justify-self: flex-end;
  margin: 0 0 0 0.5rem;
  padding: 0;
}

.bug-topic {
  flex-grow: 3;
}

.cycle-bugs .bug-actions {
  /* border-top: 1px solid #ddd; */
  margin-top: 1rem;
  padding-top: 1rem;
}

.cycle-bugs .bug-details p {
  display: flex;
  flex-direction: column;
  overflow-wrap: anywhere;
}

.cycle-bugs .bug-details .created-at {
  color: #999;
  font-size: 0.875rem;
}

.cycle-bugs .bug-details span {
  font-weight: 700;
}

.cycle-bugs .bug-details p,
.cycle-bugs .bug-details span {
  white-space: pre-wrap;
}

.cycle-bugs .bug-details .tester-device-details {
  margin-top: 0;
}

.cycle-bugs .bug-details .tester-device-details p {
  flex-direction: row;
}

.cycle-bugs .bug-details .tester-name {
  font-size: 1.125rem;
  font-weight: 700;
}

.cycle-bugs .bug-attachments {
  display: flex;
}

.cycle-bugs .bug-status-toggle {
  margin-top: 1rem;
  padding-top: 1rem;
}
.cycle-bugs .bug-status-toggle .ui.buttons .disabled.button {
  background: #1279c6;
  opacity: 1 !important;
}

.bug-attachment {
  align-items: flex-start;
  background: #fff;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  margin: 0.5rem 2rem 0.5rem 0;
  max-width: 240px;
}

.bug-attachment a {
  display: flex;
}

.bug-attachment img {
  max-width: 100%;
}

.bug-attachment .non-image-attachment {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  padding: 1rem;
  width: 100%;
}

.bug-attachment .undo-upload,
.bug-attachment .delete-upload {
  margin: 0.25rem;
  min-width: auto;
}

.account-tester .cycle-accept .requested-devices {
  margin-bottom: 2rem;
}

.account-tester .cycle-accept-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem 0;
}

.account-tester .cycle-accept-actions .accept {
  margin: 2rem 0 0;
}

.account-tester .cycle-accept-actions .terms {
  margin: 0.5rem 0 0;
  padding-bottom: 0;
}

.cycle-details-table td {
  white-space: pre-wrap;
}

.account-tester .cycle-details-table .links-list {
  list-style-type: none;
  padding-left: 0;
}

.account-tester .cycle-messages,
.account-customer .cycle-messages {
  align-items: baseline;
  display: flex;
  flex-direction: column;
}

.account-tester .cycle-messages textarea,
.account-customer .cycle-messages textarea {
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 1rem;
}

.account-customer aside .summary {
  align-items: center;
  display: flex;
  padding: 1rem;
}

.account-customer aside .summary .avatar {
  border-radius: 50%;

  margin-right: 1rem;
}

.account-customer aside .details {
  flex-direction: column;
}

.account-customer aside .details span {
  display: flex;
  flex: 1 0 auto;
}

.account-customer aside .summary .details .name,
.account-tester aside .summary .details .first-name,
.account-tester aside .summary .details .last-name {
  display: block;
  font-size: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
  white-space: nowrap;
}

.account-customer aside .menu {
  border: 0 none !important;
  width: 100% !important;
}

.account-customer .cycle-details h3 {
  font-size: 1.5rem;
}

.account-customer .cycle-details .testers-list {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.account-customer .cycle-details .testers-list .tester-preview {
  flex: 0 0 calc(50% - 1rem);
  padding: 0.5rem;
  flex-direction: row;
  /* max-width: calc(50% - 1rem); */
}

.account-customer .tester-preview .dv-star-rating {
  padding: 0 0.5rem 0 0;
}

@media screen and (max-width: 800px) {
  .account-customer .cycle-details .testers-list .tester-preview {
    flex: 0 0 100%;
    /* max-width: 100%; */
  }
}

.account-customer aside .messages-link,
.account-customer aside .messages-link.active {
  align-items: center;
  display: flex !important;
  justify-content: space-between;
}

.account-customer aside .messages-link .count {
  background: #ffad33;
  border-radius: 3px;
  display: flex;
  padding: 0.125rem 0.375rem;
}

.account-customer main {
  width: 100%;
}

.account-customer .cycles-table .resubmit-button {
  margin: 0 0.25rem 0 0.75rem;
}

.account-customer .new-cycle {
  margin-bottom: 1rem;
}

.account-customer .cycle-details-table .device-list,
.account-customer .cycle-details-table .links-list {
  list-style-type: none;
  padding-left: 0;
}

.account-customer .cycle-details-table .device-list li {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.account-customer .cycle-details-table .device-list li span {
  font-weight: 700;
}

.account-tester .cycle-details-table .platform-url,
.account-customer .cycle-details-table .platform-url {
  display: flex;
  justify-content: flex-start;
  margin: 0.25rem 0;
}
.account-tester .cycle-details-table .platform-url .name,
.account-customer .cycle-details-table .platform-url .name {
  width: 120px;
}

.account-tester .cycle-details-table .platform-url .name,
.account-tester .cycle-details-table .platform-url .url,
.account-customer .cycle-details-table .platform-url .name,
.account-customer .cycle-details-table .platform-url .url {
  margin: 0 1rem;
}

.account-customer .cycle-details-table .platform-url .url,
.account-customer .cycle-details-table .platform-url .input {
  display: flex;
  margin: 0 1rem;
  flex: 1 0 auto;
}

.account-customer .cycle-details-table .platform-url span,
.account-customer .cycle-details-table .platform-url .button {
  margin-left: auto;
}

.account-customer .devices-tested p {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  width: 280px;
}

.account-customer .devices-tested span {
  color: #1d4b71;
  font-weight: 700;
  float: right;
}

.account-customer .tester-preview .tester-summary {
  flex-direction: column;
}

.account-customer .tester-preview .tester-summary .tester-rating span {
  font-size: 1rem;
}

.account-customer .tester-details-table {
  margin-bottom: 1rem !important;
}

.customer-balance {
  color: #1d4b71;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1rem 0;
}

.cycle-messages .message-tabs {
  width: 100%;
}

.cycle-messages .messages-container {
  overflow-y: scroll;
  max-height: 600px;
  width: 100%;
}

.cycle-messages .message-form {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0;
  padding: 1rem;
  width: 80%;
}

.account-customer .cycle-messages .message-form {
  margin: 2rem 0 0;
  width: 100%;
}

.cycle-messages .chat-message {
  background: #f5f5f5;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
  max-width: 80%;
}

.cycle-messages .chat-message .avatar {
  border-radius: 50%;
  margin: 0.5rem;
}

.cycle-messages .chat-message > div {
  display: flex;
  flex-direction: row;
  white-space: pre-wrap;
}

.cycle-messages .chat-message.right {
  background: #ccffcc;
  margin-left: auto;
}

.cycle-messages .chat-message p {
  word-break: break-word;
}

.cycle-messages .chat-message.right .info {
  text-align: right;
}

.cycle-messages .chat-message.right > div {
  display: flex;
  flex-direction: row-reverse;
}

.cycle-messages .chat-message.right p {
  margin-right: auto;
}

.cycle-messages .send {
  margin: 1rem 0;
}

.account-customer .favorite-list {
  display: flex;
  flex-wrap: wrap;
}

.customer-review-form {
  background: #f5f5f5;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.customer-review-form .rating {
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  margin: 0 0 0.5rem;
}

.customer-review-form .checkbox {
  margin: 0 0 0.5rem;
}

.customer-review-form textarea {
  height: 160px;
  width: 480px;
  margin: 0 0 0.5rem;
}

.account-customer .favorite-tester {
  display: flex;
  margin: 1rem;
  padding: 1rem;
  width: calc(50% - 2rem);
}

.account-customer .favorite-tester .avatar {
  border-radius: 50%;
  margin-right: 1rem;
}

.account-customer .favorite-tester:hover {
  background: #f5f5f5;
}

.account-customer .favorite-tester-details .profile .avatar {
  float: left;
  margin: 0 1rem 0 0;
}

.account-customer .favorite-tester-details .review {
  background: #f5f5f5;
  border-radius: 6px;
  display: flex;
  overflow-wrap: anywhere;
  margin: 1rem 0;
  padding: 1rem;
}

.account-customer .favorite-tester-details .review .avatar {
  border-radius: 50%;
  margin-right: 1rem;
}

.account-customer .favorite-tester-details .rating {
  align-items: center;
  display: flex;
}

.account-customer .favorite-tester-details .dv-star-rating {
  font-size: 1.5rem;
  margin: 0 0.5rem 0 0;
}

.account-customer .favorite-tester-details .name {
  font-weight: 700;
}

.account-customer .favorite-tester-details .name span {
  font-weight: 400;
}

.account-customer .favorite-tester-details .review .review-text {
  font-style: italic;
  white-space: pre-wrap;
}

.account-customer .favorite-tester-details .review .project-details {
  color: #666;
  display: flex;
  font-size: 0.75rem;
  justify-content: space-between;
}

.registerconfirm .heading {
  background: url('public/images/emailHeader.jpg') no-repeat 0 -270px / 100% 300%;
}

.registerconfirm .inner {
  padding: 4rem 0;
}

.registerconfirm p {
  font-size: 1.5rem;
}

.registerconfirm .email {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
}

.registerconfirm .email span {
  background: #356c9b;
  color: #fff;
  font-size: 2rem;
  padding: 2rem;
}

.password-reset.page .inner {
  padding: 8rem 0;
}

footer .inner {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}

footer .inner p {
  display: flex;
  flex: 0 0 33%;
}

footer .inner .footer-social ul {
  display: flex;
  flex: 0 0 33%;
  font-size: 2rem;
  list-style: none;
  margin: 0;
}

footer .inner .footer-links {
  list-style-type: none;
  text-align: right;
  flex: 0 0 33%;
  margin: 0;
}

.input-devices {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.change-password-modal .ui.input.error > input[type='password'] {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
  box-shadow: none;
}

.flag-icon {
  background-size: cover;
  border: 1px solid #3a4240;
  width: 1.5rem;
}

.ui.label {
  background-color: #32448308;
  color: #324483;
  padding: 0.6em 0.9em;
  font-size: 14px !important;
}

.ui.active.label {
  background-color: #32448308;
  color: #324483;
  padding: 0.6em 0.9em;
  font-size: 14px !important;
}

/*PAYPAL RELATED CLASSES*/
.paypal-button-container {
  border-radius: 5px;
  background-color: #ffffff;
  padding: 20px;
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
}
.card_container {
  border-radius: 5px;
  background-color: #ffffff;
  padding: 20px;
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
}
.card_field {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  height: 40px;
  background: white;
  font-size: 17px;
  color: #3a3a3a;
  font-family: helvetica, tahoma, calibri, sans-serif;
}
